<template>
    <div class="rq-container content-wrapper file-1099-action-dialog">
        <rq-banner
            variant="error"
            title="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="v$.$error"
        />
        <rq-page-section :title="selectedFilesTitle" headerSize="sm">
            <div class="table-responsive">
                <table class="table table-fixed table-sm">
                    <thead>
                        <tr>
                            <th scope="col" class="col-2 file1099-text">Region</th>
                            <th scope="col" class="col-2 file1099-text">Company ID</th>
                            <th scope="col" class="col-3 file1099-text">Name</th>
                            <th scope="col" class="col-2 file1099-text">City</th>
                            <th scope="col" class="col-1 file1099-text">State</th>
                            <th scope="col" class="col-2 file1099-text">Phone</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in titleCompanies" :key="item.companyID">
                            <td class="col-2 file1099-text"><span v-if="item.regionDisplay">{{item.regionDisplay}}</span><span v-else v-html="nbsp"/></td>
                            <td class="col-2 file1099-text"><span v-if="item.companyID">{{item.companyID}}</span><span v-else v-html="nbsp"/></td>
                            <td class="col-3 file1099-text"><span v-if="item.name">{{item.name}}</span><span v-else v-html="nbsp"/></td>
                            <td class="col-2 file1099-text"><span v-if="item.city">{{item.city}}</span><span v-else v-html="nbsp"/></td>
                            <td class="col-1 file1099-text"><span v-if="item.state">{{item.state}}</span><span v-else v-html="nbsp"/></td>
                            <td class="col-2 file1099-text"><span v-if="item.phone">{{item.phone}}</span><span v-else v-html="nbsp"/></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </rq-page-section>
        <rq-page-section title="Report Filters" headerSize="sm" :borderless="isGenerateReport">
            <template #header-actions>
                <ul class="nav" v-if="isGenerateReport">
                    <rq-radio-group
                        id="rdo_options"
                        automation_id="rdo_options"
                        v-model="selected1099FileType"
                        :options="i1099FileTypes"
                        inline
                    />
                </ul>
                <ul class="nav" v-else>
                    <li class="nav-item ps-4" v-rq-tooltip.hover.top="{ title: 'Select if this is a test file' }">
                        <b-form-checkbox
                            automation_id="chk_test_filing"
                            id="chk_test_filing"
                            v-model="createFile.testFiling"
                            >Test Filing
                        </b-form-checkbox>
                    </li>
                    <li class="nav-item ps-3" v-rq-tooltip.hover.top="{ title: 'Select if this is the last year for filing electronically' }">
                        <b-form-checkbox
                            automation_id="chk_last_filing"
                            id="chk_last_filing"
                            v-model="createFile.lastFiling"
                            >Last Filing
                        </b-form-checkbox>
                    </li>
                </ul>
            </template>
            <div class="row">
                <div class="col-4">
                    <div class="row">
                        <div class="col-6 form-group">
                            <label for="dtp_report_date_range">Report Date Range</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'dtp_report_date_range', id: 'dtp_report_date_range' }"
                                :items="reportDateRangeItems"
                                value-expr="id"
                                display-expr="name"
                                v-model="selectedDateRange"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label for="txt_filter_date_range">Date Range</label>
                            <rq-date-range
                                id="txt_filter_date_range"
                                v-model:start-date="filterDateFrom"
                                v-model:end-date="filterDateTo"
                                type="date"
                                match-value="before-clear"
                                match-field="end"
                                show-calendar-drop-downs
                                show-clear-buttons
                                no-calendars
                                borderless
                                :disabled="dateRangeDisabled"
                                format="MM/dd/yyyy"
                            />
                        </div>
                    </div>
                    <div class="row"  v-if="showIRSFileTypeSelection">
                        <div class="col form-group">
                            <label for="txt_filter_date_range">IRS File Type</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'dtp_irs_format', id: 'dtp_irs_format' }"
                                :items="irsFileFormats"
                                class="form-control"
                                value-expr="id"
                                display-expr="name"
                                v-model="selectedIRSFileFormat"
                            />
                        </div>                        
                    </div>
                </div>
                <div v-if="isGenerateReport" class="col-8">
                    <div class="row">
                        <div class="col-6 form-group">
                            <label for="dtp_report_staff_role">Staff Role</label>
                            <!-- <dx-select-box
                                :input-attr="{ automation_id: 'dtp_report_staff_role', id: 'dtp_report_staff_role' }"
                                :items="staffRoles"
                                value-expr="id"
                                display-expr="name"
                                v-model="selectedRole"
                                @value-changed="onSelectedRolesChanged"
                            /> -->
                            <dx-tag-box
                                id="dtp_report_staff_role"
                                automation_id="dtp_report_staff_role"
                                class="form-control"
                                :data-source="staffRoles"
                                display-expr="name"
                                value-expr="id"
                                :show-selection-controls="true"
                                :show-clear-button="true"
                                :max-displayed-tags="2"
                                :show-drop-down-button="true"
                                apply-value-mode="useButtons"
                                v-model="selectedRoles"
                                @value-changed="onSelectedRolesChanged"
                            />
                        </div>
                        <div class="col-6 form-group">
                            <label for="tb_staff">Staff Member</label>
                            <!-- <dx-select-box
                                :input-attr="{ automation_id: 'dtp_report_staff', id: 'dtp_report_staff' }"
                                :items="staffMembers"
                                value-expr="id"
                                display-expr="name"
                                v-model="selectedStaffMembers"
                            /> -->
                            <dx-tag-box
                                id="dtp_report_staff"
                                automation_id="dtp_report_staff"
                                class="form-control"
                                :data-source="staffMembers"
                                display-expr="name"
                                value-expr="id"
                                :show-selection-controls="true"
                                :show-clear-button="true"
                                :max-displayed-tags="2"
                                :show-multi-tag-only="false"
                                :show-drop-down-button="true"
                                apply-value-mode="useButtons"
                                v-model="selectedStaffMembers"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <label for="tb_orderStatus">Order Status</label>
                            <dx-tag-box
                                id="tb_orderStatus"
                                automation_id="tb_orderStatus"
                                class="form-control"
                                :data-source="orderStatuses"
                                display-expr="name"
                                value-expr="id"
                                :show-selection-controls="true"
                                :show-clear-button="true"
                                :max-displayed-tags="2"
                                :show-multi-tag-only="false"
                                :show-drop-down-button="true"
                                apply-value-mode="useButtons"
                                v-model="selectedOrderStatus"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-4" v-if="!isGenerateReport">
                    <div class="row">
                        <div :class="{ 'col col-12 form-group':true, 'has-error':v$.correctionFiles.$error }">
                            <label for="dtp_print_correction">Print Correction</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'dtp_print_correction', id: 'dtp_print_correction' }"
                                :items="printCorrectionItems"
                                class="form-control"
                                value-expr="id"
                                display-expr="name"
                                v-model="selectedPrintCorrection"
                                @value-changed="onPrintCorrectionChange"
                            />
                            <rq-validation-feedback>At Least one file must be selected if Print Correction is Yes</rq-validation-feedback>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label for="list_corrected_files">Corrected Files</label>
                            <rq-list-box
                                ref="correctedFiles"
                                :data-source="correctedFiles"
                                height=200
                                :disabled="disableCorrectedFiles"
                                v-model="selectedCorrectedFiles"
                                multi-select
                            />
                        </div>
                    </div>
                </div>
                <div class="col-4" v-if="!isGenerateReport">
                    <div class="row">
                        <div :class="{ 'col col-12 form-group':true, 'has-error':v$.selectedCompany.$error }">
                            <label for="dtp_company">Company With Filing Info</label>
                            <dx-select-box
                                :input-attr="{ automation_id: 'dtp_company', id: 'dtp_company' }"
                                :items="titleCompanies"
                                value-expr="companyID"
                                display-expr="name"
                                v-model="selectedCompany"
                                :disabled="isSingleTitleCompany"
                            />
                            <rq-validation-feedback>Filing Company is required</rq-validation-feedback>
                        </div>
                    </div>
                    <div class="row">
                        <div :class="{ 'col col-12 form-group':true, 'has-error': v$.companyContact.$error }">
                            <rq-action-form-group
                                action-class="overridden"
                                label="Contact Name"
                                labelFor="txt_contact_name"
                                :show-action="hasContactNameOverride"
                                @action-click="onContactRevert('name')"
                                action-automation-id="txt_contact_name_revert"
                                action-label="Revert"
                            >
                                <template #default>
                                    <input
                                        automation_id="txt_contact_name"
                                        v-model="companyContact.name"
                                        type="text"
                                        class="form-control"
                                        id="txt_contact_name"
                                        maxlength="40"
                                    >
                                </template>
                            </rq-action-form-group>
                            <rq-validation-feedback>Contact Name is required</rq-validation-feedback>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 form-group">
                            <rq-action-form-group
                                action-class="overridden"
                                label="Contact Phone"
                                labelFor="txt_contact_phone"
                                :show-action="hasContactPhoneOverride"
                                @action-click="onContactRevert('phone')"
                                action-automation-id="txt_contact_phone_revert"
                                action-label="Revert"
                            >
                                <template #default>
                                    <rq-masked-input id="txt_contact_phone" placeholder="Phone" v-model="companyContact.phone" maskType="phone" isMasked="true" maxlength="25"></rq-masked-input>
                                </template>
                            </rq-action-form-group>
                        </div>
                        <div class="col-6 form-group">
                            <rq-action-form-group
                                action-class="overridden"
                                label="Contact Extension"
                                labelFor="txt_contact_extension"
                                :show-action="hasContactExtensionOverride"
                                @action-click="onContactRevert('extension')"
                                action-automation-id="txt_contact_extension_revert"
                                action-label="Revert"
                            >
                                <template #default>
                                    <input
                                        automation_id="txt_contact_extension"
                                        v-model="companyContact.extension"
                                        type="text"
                                        class="form-control"
                                        id="txt_contact_extension"
                                        maxlength="20"
                                    >
                                </template>
                            </rq-action-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <rq-action-form-group
                                action-class="overridden"
                                label="Contact Email"
                                labelFor="txt_contact_email"
                                :show-action="hasContactEmailOverride"
                                @action-click="onContactRevert('email')"
                                action-automation-id="txt_contact_email_revert"
                                action-label="Revert"
                            >
                                <template #default>
                                    <input
                                        automation_id="txt_contact_email"
                                        v-model="companyContact.email"
                                        type="text"
                                        class="form-control"
                                        id="txt_contact_email"
                                        maxlength="150"
                                    >
                                </template>
                            </rq-action-form-group>
                        </div>
                    </div>
                </div>
            </div>
        </rq-page-section>
        <rq-page-section title="Report Status" headerSize="sm" borderless collapsible v-if="!isGenerateReport">
            <div class="row">
                <div class="col-8 form-group">
                    <rq-list-box
                        :data-source="messageList"
                        height=150
                        :disabled="true"
                    />
                </div>
            </div>
        </rq-page-section>
    </div>
</template>
<script>
const reportDateRange = {
    LastYear: 0,
    YearToDate: 1,
    CustomDate: 2
}
const printCorrection = {
    No: 0,
    Yes: 1
}
import RqListBox from "@/shared/components/rq/list-box/RqListBox";
import { ListBoxItemModel } from "@/shared/models/models";
import { I1099FilingRequestDto, I1099FilingResultDto, filing1099ActionEnum, I1099FileTypeOption } from "../models";
import { required } from '@vuelidate/validators';
import { useVuelidate } from "@vuelidate/core";
import { mapState, mapGetters } from "vuex";
import { OrderStatus } from "@/shared/models/enums";
import { JobTypes } from "@/modules/configuration/enums";
import { DateTime } from "luxon";
import { DateTimeHelper } from '@/shared/utilities';
export default {
    components: { RqListBox },
    props: {
        titleCompanies: {
            type: Array,
            required: true
        },
        action: {
            type: Number,
            required: true
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        };
    },
    data() {
        const self = this;
        return {
            errorMessage: "",
            createFile: {},
            selectedDateRange: 0,
            selectedPrintCorrection: 0,
            selectedIRSFileFormat: 0,
            selectedCompany: null,
            reportDateRangeItems: [
                { id: reportDateRange.LastYear, name: 'Last Year'},
                { id: reportDateRange.YearToDate, name: 'Year To Date'},
                { id: reportDateRange.CustomDate, name: 'Custom Date'}
            ],
            printCorrectionItems: [
                { id: printCorrection.Yes, name: 'Yes'},
                { id: printCorrection.No, name: 'No'},
            ],
            irsFileFormats: [
                { id: 0, name: '.txt' },
                { id: 1, name: '.csv' }
            ],
            originalCompanyContact: {},
            companyContact: {},
            correctedFiles: [],
            selectedCorrectedFiles: [],
            titleCompanyDetailsList: [],
            filterDateFrom: '',
            filterDateTo: '',
            messageList: [],
            nbsp: '&nbsp;',
            fileComplete: false,
            selectedOrderStatus: [OrderStatus.Closed],
            staffMembers: [],
            selectedStaffMembers: [],
            selectedRoles: [],
            i1099FileTypes: [
                { value: I1099FileTypeOption.I1099FilesOnly, text: 'All 1099 Files'},
                { value: I1099FileTypeOption.MissingInfoFilesOnly, text: 'Missing Info Files Only'},
            ],
            selected1099FileType: I1099FileTypeOption.I1099FilesOnly,
            staffRoles: [
                { id: JobTypes.EscrowOfficer, name: JobTypes.displayValue(JobTypes.EscrowOfficer) },
                { id: JobTypes.ClosingAgent, name: JobTypes.displayValue(JobTypes.ClosingAgent) }
            ]
        }
    },
    computed: {
        ...mapGetters([
            "lookupHelpers",
            "lookupItems"
        ]),
        ...mapState({
            user: state => state.authentication.session.user
        }),
        dateRangeDisabled() { return this.selectedDateRange != reportDateRange.CustomDate},
        isSingleTitleCompany() { return this.titleCompanies.length === 1; },
        disableCorrectedFiles() { return this.selectedPrintCorrection === 0},
        isGenerateReport() { return this.action === filing1099ActionEnum.GenerateReport},
        selectedFilesTitle() {
            return `Selected Title Companies (${this.titleCompanies.length})`
        },
        showIRSFileTypeSelection() { return (this.action === filing1099ActionEnum.CreateIRSFile) && (!_.isEmpty(this.filterDateFrom) && DateTimeHelper.getDateFromString(this.filterDateFrom).year >= 2024); },
        hasValidFileSelection() { return (this.selectedPrintCorrection === 1 && _.size(this.selectedCorrectedFiles) > 0) || this.selectedPrintCorrection === 0; },
        hasContactNameOverride() { return !_.isNullOrEmpty(this.originalCompanyContact.name) && !_.isNil(this.selectedCompany) && !_.isEqual(this.originalCompanyContact.name, this.companyContact.name); },
        hasContactEmailOverride() { return !_.isNullOrEmpty(this.originalCompanyContact.email) && !_.isNil(this.selectedCompany) && !_.isEqual(this.originalCompanyContact.email, this.companyContact.email); },
        hasContactPhoneOverride() { return !_.isNullOrEmpty(this.originalCompanyContact.phone) && !_.isNil(this.selectedCompany) && !_.isEqual(this.originalCompanyContact.phone, this.companyContact.phone); },
        hasContactExtensionOverride() { return !_.isNullOrEmpty(this.originalCompanyContact.extension) && !_.isNil(this.selectedCompany) && !_.isEqual(this.originalCompanyContact.extension, this.companyContact.extension); },
        hasValidContact() { return (!_.isNil(this.selectedCompany) && _.size(this.companyContact.name) > 0) || _.isNil(this.selectedCompany); },
        orderStatuses() {
            return OrderStatus.lookupItems;
        },
        reportParameters() {
            return {
                p_StartDate: _.isNil(this.filterDateFrom) ? null : this.filterDateFrom,
                p_EndDate: _.isNil(this.filterDateTo) ? null : this.filterDateTo,
                p_TitleCompanies: _.map(this.titleCompanies, x => x.companyID),
                p_Regions: _.uniq(_.map(this.titleCompanies, x => x.regionID)),
                p_Option1099: this.selected1099FileType,
                p_OrderStatus: this.selectedOrderStatus,
                p_StaffTypes1099: this.selectedRoles,
                p_Staffs: this.selectedStaffMembers
            }
        }
    },
    validations() {
        const self = this;
        return {
            companyContact: {
                name: {
                    required,
                },
                hasValidContact(val) {
                    return self.hasValidContact;
                }
            },
            correctionFiles: {
                hasValidFileSelection(val) {
                    return self.hasValidFileSelection;
                }
            },
            selectedCompany: { required, },
        };
    },
    watch: {
        "v$.$error":{
            handler: function(newValue, oldValue) {
                this.$emit(`${newValue ? "disable" : "enable"}-ok`);
            },
            deep: true,
            immediate: true
        },
        selectedDateRange: {
            handler: function(newValue, oldValue) {
                this.setDateRangeValue(newValue);
            },
            immediate: true
        },
        selectedCompany: {
            handler: function(newValue, oldValue) {
                this.setContactData(newValue);
            },
            immediate: false
        },
        selectedPrintCorrection: {
            handler: function(newValue, oldValue) {
                this.getCorrectedFiles();
            },
            immediate: false
        },
        filterDateFrom: {
            handler: function(newValue, oldValue) {
                this.getCorrectedFiles();
            },
            immediate: false
        },
        filterDateTo: {
            handler: function(newValue, oldValue) {
                this.getCorrectedFiles();
            },
            immediate: false
        }
    },
    created() {
        let self = this;
        if(self.isSingleTitleCompany){
            self.selectedCompany = self.titleCompanies[0].companyID;
        }
        self.getCorrectedFiles();
    },
    methods: {

        createRequest() {
            let request = new I1099FilingRequestDto();
            request.testing = _.parseBool(this.createFile.testFiling, false);
            request.correction = this.selectedPrintCorrection === 1;
            request.lastFiling = _.parseBool(this.createFile.lastFiling, false); // this needs to be fixed from changes
            request.filingTitleCompanyCompanyID = this.selectedCompany;
            request.titleCompanyIDs = _.map(this.titleCompanies, "companyID") || [];
            request.correctedFileOrdersID = _.map(this.selectedCorrectedFiles, "itemID") || [];
            request.startDate = this.filterDateFrom || null;
            request.endDate = this.filterDateTo || null;
            request.contactName = this.companyContact.name;
            request.contactEmail = this.companyContact.email;
            request.contactPhone = this.companyContact.phone;
            request.contactPhoneExt = this.companyContact.extension;
            request.fileType = this.selectedIRSFileFormat;

            return request;
        },
        setDateRangeValue(dateRangeValueType=0) {
            const self = this;
            let currentDate = DateTime.now();
            let lastYear = currentDate.year - 1
            let currentYear = currentDate.year;
            let fromDate = currentDate;
            let toDate = currentDate;

            switch(dateRangeValueType)
            {
                case reportDateRange.LastYear:
                    fromDate = DateTime.fromFormat(`01/01/${lastYear}`, "MM/dd/yyyy");
                    toDate = DateTime.fromFormat(`12/31/${lastYear}`, "MM/dd/yyyy");
                    break;
                case reportDateRange.YearToDate:
                    fromDate = DateTime.fromFormat(`01/01/${currentYear}`, "MM/dd/yyyy");
                    toDate = currentDate;
                    break;
            }

            self.filterDateFrom = fromDate.toFormat("MM/dd/yyyy");
            self.filterDateTo = toDate.toFormat("MM/dd/yyyy");
        },
        setContactData(companyId) {
            let self = this;
            if (_.isNil(companyId)) {
                self.populateCompanyContact({contact1099: null, contact1099Phone: null, contact1099PhoneExt: null, contact1099EmailAddress: null});
                return;
            }
            let titleCompanyDetail = _.find(self.titleCompanyDetailsList, x => x.companyID === companyId);
            if(!titleCompanyDetail) {
                let promise = self.$api.CompaniesApi.getTitleCompany(companyId);
                self.$rqBusy.wait(promise)
                    .then(result => {
                        self.titleCompanyDetailsList = [...self.titleCompanyDetailsList, result];
                        self.populateCompanyContact(result);
                    })
                    .catch(error => {
                        self.$toast.error(`Failed to Get Title Company: ${error.message}`);
                    });
            }
            else {
                self.populateCompanyContact(titleCompanyDetail);
            }
        },
        populateCompanyContact(data) {
            this.companyContact = {
                name: data.contact1099,
                phone: data.contact1099Phone,
                extension: data.contact1099PhoneExt,
                email: data.contact1099EmailAddress
            }
            this.originalCompanyContact = _.clone(this.companyContact);
        },
        getCorrectedFiles() {
            const self = this;
            if(self.action === filing1099ActionEnum.GenerateReport) return;
            if(_.isNullOrEmpty(self.filterDateFrom) || _.isNullOrEmpty(self.filterDateTo)) {
                self.$emit("disable-ok");
                return;
            } else {
                self.$emit("enable-ok");
            }
            if(!self.disableCorrectedFiles)
            {
                let selectedTitleCompanyIds = _.map(self.titleCompanies, x => x.companyID);
                let promise = self.$api.CompaniesApi.getCorrectedFiles(selectedTitleCompanyIds, self.filterDateFrom, self.filterDateTo);
                self.$rqBusy.wait(promise)
                    .then(result => {
                        self.correctedFiles = _.map(result, item => new ListBoxItemModel({itemID: item.ordersID, itemName: item.fileNumber}));
                    })
                    .catch(error => {
                        self.$toast.error(`Failed to Get Corrected Files: ${error.message}`);
                    });
            }
            else
                self.correctedFiles = [];
        },
        onContactRevert(field) {
            _.set(this.companyContact, field, _.get(this.originalCompanyContact, field));
        },
        onPrintCorrectionChange(e) {
            if (e.value == 0) {
                this.selectedCorrectedFiles = [];
            }
        },
        onSelectedRolesChanged(e) {
            let self = this;
            let staffLookup = [];

            let staffs = self.lookupHelpers.getAllStaff();

            if (!_.isEmpty(self.selectedRoles)) {
                staffLookup = _.filter(staffs, (item) => {
                    return _.some(item.jobDutyIDs, (duty) =>
                        _.some(self.selectedRoles, (staffDuty) => staffDuty == duty ));
                });
            }

            self.staffMembers = _.map(_.uniqBy(staffLookup, 'id'), (item) => ({ id: item.id, name: item.name, inactive: item.isInactive }));
        },
        processGenerateReport() {},
        processSendFile() {
            //9/17/2020 KPACA - simulation only, not sure yet how this works
            let messages = [
                { id: 1, dateTime: '1/1/2020', content: 'Test Message Create File', isError: false},
                { id: 2, dateTime: '1/1/2020', content: 'Test Message Create File', isError: false},
                { id: 3, dateTime: '1/1/2020', content: 'Test Message Create File', isError: true},
                { id: 4, dateTime: '1/1/2020', content: 'Test Message Create File', isError: false },
                { id: 5, dateTime: '1/1/2020', content: 'Test Message Create File', isError: false },
                { id: 6, dateTime: '1/1/2020', content: 'Test Message Create File', isError: false },
                { id: 7, dateTime: '1/1/2020', content: 'Test Message Create File', isError: false },
                { id: 8, dateTime: '1/1/2020', content: 'Test Message Create File', isError: false },
                { id: 9, dateTime: '1/1/2020', content: 'Test Message Create File', isError: false }
            ];
            this.messageList = _.map(messages, item => new ListBoxItemModel({itemID: item.id, itemName: `${item.dateTime} ${item.content}`, isError: item.isError}));

            let hasMissingItems = true;
            if(hasMissingItems){
                let ok = () => {
                    let successMessage = [
                        { id: 10, dateTime: '1/1/2020', content: '___________________________', isError: false },
                        { id: 11, dateTime: '1/1/2020', content: 'Creating the Reporting File', isError: false },
                        { id: 12, dateTime: '1/1/2020', content: 'There are', isError: false }
                    ];

                    self.messageList = _.concat(self.messageList, _.map(successMessage, item => new ListBoxItemModel({itemID: item.id, itemName: `${item.dateTime} ${item.content}`, isError: item.isError})));
                    return true;
                }
                this.$dialog.confirm(`Confirm Send File`, 'Missing items are found. Do you wish to continue?', ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
                return false;
            }
            return true;
        },
        processCreateIRSFile() {
            let self = this;
            self.v$.$touch();
            if (self.v$.$error) { return Promise.reject('Please provide all required fields.'); }
            self.fileComplete = false;
            self.messageList = [];
            let request = self.createRequest();
            let apiPromise = self.$api.CompaniesApi.create1099File(request);
            return self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        let result = new I1099FilingResultDto(data);
                        if (result.totalCount == 0) {
                            self.messageList.push(new ListBoxItemModel({itemID: 1, itemName: `File was not created! No files found with closing date for given date range.`, isError: true}));
                        } else {
                            self.messageList.push(new ListBoxItemModel({itemID: 0, itemName: `File "${result.fileName}" has been created, check your download folder!`, isError: false}));
                            self.messageList.push(new ListBoxItemModel({itemID: 1, itemName: `There ${result.totalCount == 1 ? 'was' : 'were'} ${result.totalCount} seller record${result.totalCount == 1 ? '' : 's'} in the file`, isError: false}));
                            self.fileComplete = true;
                            self.$emit("update-dialog-options", { okTitle: "Close", okOnly: true });
                            self.saveToClient(result.fileName, result.fileContent);
                        }
                        if (result.missingAddressCount > 0) self.messageList.push(new ListBoxItemModel({itemID: 2, itemName: `${result.missingAddressCount} seller${result.missingAddressCount == 1 ? ' is' : 's are'} missing address information`, isError: true}));
                        if (result.missingIdentityCount > 0) self.messageList.push(new ListBoxItemModel({itemID: 3, itemName: `${result.missingIdentityCount} seller${result.missingIdentityCount == 1 ? ' is' : 's are'} missing identity number information`, isError: true}));
                        if (result.missingNameCount > 0) self.messageList.push(new ListBoxItemModel({itemID: 4, itemName: `${result.missingNameCount} seller${result.missingNameCount == 1 ? ' is' : 's are'} missing valid name information`, isError: true}));
                        if (result.missingControlCount > 0) self.messageList.push(new ListBoxItemModel({itemID: 5, itemName: `${result.missingControlCount} seller${result.missingControlCount == 1 ? ' is' : 's are'} missing valid control information`, isError: true}));
                        return false;
                    })
                    .catch(error => {
                        self.fileComplete = false;
                        console.error(error);
                        self.$emit("update-dialog-options", { okTitle: "Create File", okOnly: false });
                        self.$toast.error({ message: `Error creating 1099 file.` });
                    });

        },
        saveToClient(fileName, fileContent) {
            const self = this;
            let binary = _.base64ToArrayBuffer(fileContent);
            let mimeType = fileName.endsWith('.csv') ? 'text/csv' : 'text/plain';
            let blob = new Blob([binary], {type: mimeType});
            if (window.navigator.msSaveOrOpenBlob) // IE10+
                window.navigator.msSaveOrOpenBlob(blob,fileName);
            else { // Others
                let a = document.createElement("a")
                let url = URL.createObjectURL(blob);
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                setTimeout(function() {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 0);
            }
        },
    }
}
</script>